import { Box, Flex, Text, Button, Spacer, Option, Image, Grid, GridItem, Link, Mark } from "@chakra-ui/react";
import utilStyles from "./utils.module.css";
import useNFT from "../../../hooks/useNFT";
import ButtonRewardDapIndividual from "../ButtonRewardDapIndividual";

const MarketPlaceDap = () => {
  const { nftData, isLoading } = useNFT();
  return (
    <Flex marginTop="0px" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Grid 
        templateColumns={{ base: 'repeat(1, 1fr)',xs: 'repeat(2, 1fr)',md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)', }}
        gridColumnGap="25px"
        gridRowGap="50px"
        margin="50px"
      >
        {isLoading ? (
          <>
            <GridItem w="100%" position="relative" borderRadius="20px" display="flex" justifyContent="center">
              {" "}
              <Link color="teal.500" href="https://opensea.io/collection/vsion-dap-protocol" target="_blank">
                <Flex
                  display="flex"
                  flexDirection="column"
                  paddingTop="15px"
                  paddingLeft="10px"
                  paddingRight="10px"
                  justifyContent="space-between"
                  className={utilStyles.conteiner}
                >
                  {" "}
                </Flex>
              </Link>
            </GridItem>
          </>
        ) : (
          <>
            {nftData?.map((item) => (
              <GridItem
                w="300px"
                h="380px"
                backdropFilter="blur(10px);"
                bg="rgba(255, 255, 255, 0.1)"
                position="relative"
                borderRadius="20px"
                display="flex"
                justifyContent="center"
              >
                <Flex
                  display="flex"
                  flexDirection="column"
                  paddingTop="15px"
                  paddingLeft="10px"
                  paddingRight="10px"
                  justifyContent="space-between"
                  className={utilStyles.conteiner}
                >
                  <Box className={utilStyles.img}>
                    <Link color="teal.500" href={"https://dap.vsion.io/nft-sad/" + item.identifier}>
                      <Image
                        src={item.image_url}
                        alt={item.name}
                        w="100%"
                        h="100%"
                        className={utilStyles.imgNFt}
                        objectFit="cover"
                      />
                    </Link>
                  </Box>
                  <Flex justifyContent="space-around" alignItems="center" paddingBottom="20px">
                    <Box display="flex" flexDirection="column" className={utilStyles.cont}>
                      <Box display="flex" w="275px" flexDirection="row" justifyContent="space-around">
                        <Box display="flex" flexDirection="column" justifyContent="start">
                          <Text fontSize="24" textColor="white" fontWeight="600">
                            DAP NFT
                          </Text>
                        </Box>
                        <Box>
                          <Link color="teal.500" href={"https://dap.vsion.io/nft-sad/" + item.identifier}>
                            <Text textColor="yellow" fontSize="14px" mt={2} lineHeight="20px" fontWeight="700">
                              View Open sea
                            </Text>{" "}
                          </Link>
                        </Box>
                      </Box>

                      <Box alignItems="flex-start">
                        <ButtonRewardDapIndividual idNft={item.identifier}/>
                        <Link color="teal.500" href={"https://app.vsion.io/dashboard/dap/" + item.identifier}>
                          <Button
                            borderRadius="12px"
                            textColor="white"
                            w="275px"
                            h="40px"
                            display="none"
                            background="linear-gradient(25deg, #af0f7d, #58086c)"
                            className={utilStyles.btn}
                            _hover={{
                              background: "linear-gradient(329deg, #00bcd4 12%, rgba(66, 153, 225, 0.6) 100%)",
                              textColor: "white",
                              border: "none",
                              fontSize: "20px"
                            }}
                          >
                            View
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Flex>
                </Flex>
              </GridItem>
            ))}
          </>
        )}
      </Grid>
    </Flex>
  );
};
export default MarketPlaceDap;
