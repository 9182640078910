import { Box, Flex, Text, Button, Spacer, Option, Image, Mark, Link } from "@chakra-ui/react";

const HeaderPage = (tittle, midtittle, theretittle, desc, buttonHeader, img, hreflink) => {
  return (
    <Flex
      display="flex"
      flexDirection={{ base: "column-reverse", md: "row" }}
      justifyContent="space-around"
      marginTop={{ base: "50px", md: "100px" }}
      position="relative"
      alignItems="center"
      mb={4}
    >
      <Flex display="flex" flexDirection="column" gap="40px" mt={{ base: "10px", md: "80px" }} width="100%">
        <Flex>
          <Text fontSize={{ base: "40", md: "60" }} fontWeight="700" lineHeight="60px" textColor="white">
            {tittle}
            <Mark bgGradient="linear(to-l, #F7FC0E, #FF8D8D)" textColor="transparent" bgClip="text">
              {midtittle}
            </Mark>{" "}
            <br /> {theretittle}
          </Text>
        </Flex>
        <Flex w={{ base: "auto", md: "500px" }}>
          <Text fontSize={{ base: "18", md: "25" }} lineHeight="30px" textColor="white">
            {desc}
          </Text>
        </Flex>
        {buttonHeader && (
          <Flex paddingTop="10px">
            <Link color="teal.500" href={hreflink} target="_blank">
              <Button
                marginRight="5"
                borderRadius="30"
                textColor="white"
                bg="linear-gradient(329deg, #9900d1 12%, #af117d 100%)"
                _hover={{ textColor: "black", background: "white" }}
              >
                {buttonHeader}
              </Button>
            </Link>
          </Flex>
        )}
      </Flex>
      <Flex>
        <Image src={img} w="100%" h={{ base: "260px", md: "520px" }} />
      </Flex>
    </Flex>
  );
};
export default HeaderPage;
