import { Web3Button, useAddress, useContract, useContractRead, useContractWrite } from '@thirdweb-dev/react';
import DapRewardAbi from '../../../abi/DapReward.abi.json'
import { getAddress } from 'ethers/lib/utils';
import { useMemo } from 'react';
import { useToast } from '@chakra-ui/react';

interface TPropsButtonReward {
  idToken: string
}

const dapRewardAddr = "0xc8364B02bBE37D4C2E856B14599F4E5eF9c188CC"
const ButtonRewardOwner: React.FC<TPropsButtonReward> = (props) => {
  const { idToken } = props
  const toast = useToast();
  const address = useAddress()

  const { contract: DapRewardContract } = useContract(dapRewardAddr, DapRewardAbi)

  const { data: owner = "" } = useContractRead(DapRewardContract, "owner")

  const { mutateAsync } = useContractWrite(DapRewardContract, "withdrawOnwer")

  const isOwner = useMemo(() =>
    owner && address && getAddress(owner) == getAddress(address),
    [owner, address]
  )
  const sucessToast = (message: string) => {
    toast({
      title: "Success tx.",
      description: message,
      status: "success",
      duration: 9000,
      isClosable: true
    });
  };

  const errorToast = (message: string) => {
    toast({
      title: "Error tx.",
      description: message,
      status: "error",
      duration: 9000,
      isClosable: true
    });
  };
  const handlerClickWithdraw = async () => {
    try {
      if (isOwner) {
        const res = await mutateAsync({
          args: [
            idToken
          ]
        })
        sucessToast(res.receipt.transactionHash);
      }
    } catch (err: any) {
      errorToast(err.reason || "error");
    }
  }


  return (
    <>
      {isOwner && (
        <Web3Button
          contractAddress={dapRewardAddr}
          contractAbi={DapRewardAbi}
          action={() => handlerClickWithdraw()}
        >Withdraw Owner</Web3Button >)
      }
    </>
  );
};

export default ButtonRewardOwner;