import { Link } from "react-router-dom";
import { FaUserTie } from "react-icons/fa";
import { AiFillStar, AiOutlineClockCircle, AiOutlineDesktop } from "react-icons/ai";
import { Box, Image, Avatar, Heading, Text, Stack, Button } from "@chakra-ui/react";

import placeHolderCourse from "../../assets/images/placeholder-cousrce.png";

const CourseCard = () => {
  return (
    <Box
      borderRadius="20px"
      bg="white"
      border="2px solid #e8e8e8"
      marginBottom="30px"
      p={{ base: "10px 5px", md: "10px" }}
      display="flex"
      alignItems="center"
      transition="all ease .4s"
      flexDirection={{ base: "column", xl: "row" }}
      _hover={{ border: "2px solid #af117d" }}
    >
      <Box borderRadius="20px">
        <Image width="auto" className="fopa" borderRadius="20px" src={placeHolderCourse} alt="Course Img" />
      </Box>
      <Box flex={1} padding={{ base: "22px 15px", md: "45px 55px" }}>
        <Box mb="20px">
          <Text
            p={{ base: "0 15px", md: "0 13px" }}
            fontSize="14px"
            fontWeight="700"
            letterSpacing=".6px"
            textAlign="center"
            borderRadius="3px"
            textTransform="uppercase"
            transition="all ease .4s"
            backgroundColor="#FFCC33"
            color="#000"
            display="inline-block"
          >
            Busniess Learning
          </Text>
        </Box>
        <Heading as="h3" color="#000" fontSize={{ base: "24px", md: "34px" }}>
          Advance Begineer's Goal &amp; Managing Quality Course
        </Heading>
        <Stack gap="17px" flexDirection="row" mt="17px" mb={{ base: "20px", md: "40px" }}>
          <Box
            color="#444444"
            fontSize={{ base: "12px", md: "15px" }}
            display="flex"
            alignItems="center"
            gap={{ base: "3px", md: "10px" }}
          >
            <FaUserTie />
            <Text as="span" fontSize={{ base: "12px", md: "15px" }} color="#444444">
              204 Students
            </Text>
          </Box>{" "}
          <Box
            color="#444444"
            fontSize={{ base: "12px", md: "15px" }}
            display="flex"
            alignItems="center"
            gap={{ base: "3px", md: "10px" }}
          >
            <AiOutlineDesktop />
            <Text as="span" fontSize={{ base: "12px", md: "15px" }} color="#444444">
              12 Leson
            </Text>
          </Box>{" "}
          <Box
            color="#444444"
            fontSize={{ base: "12px", md: "15px" }}
            display="flex"
            alignItems="center"
            gap={{ base: "3px", md: "10px" }}
          >
            <AiOutlineClockCircle />
            <Text as="span" fontSize={{ base: "12px", md: "15px" }} color="#444444">
              2h 11m
            </Text>
          </Box>
        </Stack>

        <Stack flexDirection="row" alignItems="center" justifyContent="space-between" w="100%">
          <Text fontSize="40px" fontWeight="black" color="#af117d">
            $149
          </Text>
          <Button as={Link} to="/dashboard/academy/detail/1" className="btn-mtks">
            {" "}
            Preview Course{" "}
          </Button>
        </Stack>

        <Stack
          pt="20px"
          mt={{ base: "20px", md: "45px" }}
          borderTop="1px solid #e8e8e8"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
        >
          <Stack flexDirection="row" alignItems="center" gap="15px">
            <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
            <Text color="#444444" fontSize="14px">
              BY ANA WATSON
            </Text>
          </Stack>
          <Stack flexDirection="row" alignItems="center" gap="5px">
            <Stack flexDirection="row" alignItems="center" gap="0.2rem">
              {[1, 2, 3, 4, 5].map((star) => (
                <AiFillStar fontSize="16px" key={star} color="#f6b800" />
              ))}
            </Stack>

            <Text color="#444444" fontSize="14px" fontWeight="bold">
              (5.0)
            </Text>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default CourseCard;
