import { Box, Flex, Hide, Input, Text, Checkbox, Grid, Skeleton, Stack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { LotteryContext } from "../contextLottery";
import { SmartContract, ValidContractInstance, useAddress, useContractRead } from "@thirdweb-dev/react";
import { BaseContract } from "ethers";
import { TLottery } from "../type";
import TableHeader from "../../Header/Table";
import { ClaimPrizes } from "./ClaimPrize";
import { numberFinalToArr } from "../../../utils/lottery";
import i18next from "i18next";
import { getLotteryIdByAddress } from "../../../firebase/services/ticketLottery";
const headers = ["id", "Winner", "Finished", "winners", "Claim"];

const formatTime = (date: Date) =>
  i18next.t(" {{val, datetime}}", {
    val: date,
    lng: "en",
    formatParams: {
      val: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        date: "",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      }
    }
  });
const LotteryCard = (props: { lotteryWinnerId: number }) => {
  const { lotteryWinnerId } = props;
  const { lotteryContract } = useContext(LotteryContext);
  const { data: viewLotteryFoo } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "viewLottery",
    any[],
    TLottery
  >(lotteryContract, "viewLottery", [lotteryWinnerId]);

  const components = [
    <Box key={0} color="black" textTransform="uppercase" fontWeight="medium">
      <Text
        fontSize={{ base: "xs", sm: "sm" }}
        fontWeight="medium"
        color="GrayText"
        mb={1}
        display={{ base: "block", sm: "none" }}
      >
        Id
      </Text>
      {lotteryWinnerId}
    </Box>,
    <Box key={1} color="black" textTransform="uppercase" fontWeight="medium">
      <Text
        fontSize={{ base: "xs", sm: "sm" }}
        fontWeight="medium"
        color="GrayText"
        mb={1}
        display={{ base: "block", sm: "none" }}
      >
        Winner
      </Text>
      <Flex w={"7rem"}>
        {numberFinalToArr(viewLotteryFoo?.finalNumber || "1000000").map((num, idx) => (
          <Grid
            key={idx}
            rounded={"full"}
            w={"1.4rem"}
            h={"1.4rem"}
            gap={4}
            border={"2px solid #000"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text lineHeight={"0.5rem"}>{num}</Text>
          </Grid>
        ))}
      </Flex>
    </Box>,

    <Box key={2} color="black" textTransform="uppercase" fontWeight="medium">
      <Text
        fontSize={{ base: "xs", sm: "sm" }}
        fontWeight="medium"
        color="GrayText"
        mb={1}
        display={{ base: "block", sm: "none" }}
      >
        Finished
      </Text>
      {formatTime(new Date(viewLotteryFoo?.endTime.toNumber() || 0))}
    </Box>,

    <Box key={3} color="black" textTransform="uppercase" fontWeight="medium">
      <Text
        fontSize={{ base: "xs", sm: "sm" }}
        fontWeight="medium"
        color="GrayText"
        mb={1}
        display={{ base: "block", sm: "none" }}
      >
        winners
      </Text>
      {viewLotteryFoo?.countWinnersPerBracket[0].toString()}
    </Box>,
    <Box
      key={4}
      display={{ base: "none", lg: "block" }}
      fontSize={{ base: "xs", sm: "sm" }}
      color="black"
      textTransform="uppercase"
    >
      <ClaimPrizes lotteryWinnerId={lotteryWinnerId} winnerNumber={viewLotteryFoo?.finalNumber || 1000000}>
        Claim
      </ClaimPrizes>
    </Box>
  ];

  return viewLotteryFoo ? (
    <Box px={{ base: 4, sm: 2 }} py={{ base: 4, sm: 6 }} borderTop="1px dashed rgb(229 231 235)">
      <TableHeader
        templateColumns={{
          base: `repeat(1, minmax(0,1fr))`,
          sm: `repeat(${headers.length - 2}, minmax(0,1fr))`,
          lg: `repeat(${components.length}, minmax(0,1fr))`
        }}
        gap={{
          base: 0,
          sm: 6
        }}
        headers={components}
        final
      />
    </Box>
  ) : (
    <Stack>
      <Skeleton height="100px" rounded={"2xl"} my={6} />
    </Stack>
  );
};

const TableHistoryLottery = () => {
  const { lotteryWinnerId } = useContext(LotteryContext);
  const address = useAddress();

  const [loading, setLoading] = useState(false);
  const [hasMyParticipation, setHasMyParticipation] = useState(false);
  const [participations, setParticipations] = useState<number[]>([]);
  const [idLotteries, setIdLotteries] = useState<number[]>([]);
  const [topId, setTop] = useState<number>(lotteryWinnerId.toNumber() > 0 ? lotteryWinnerId.toNumber() : 1);
  const handleList = () => {
    if (topId > 0) {
      const length = topId >= 5 ? 5 : topId;

      if (hasMyParticipation) {
        const fooParticipation = participations.filter((par) => topId >= par);
        setIdLotteries(fooParticipation.splice(0, 5));
      } else {
        const arrId = new Array(length).fill(0).map((_, idx) => topId - idx);
        setIdLotteries(arrId);
      }
    }
  };
  useEffect(() => {
    handleList();
  }, [topId, hasMyParticipation, participations]);

  useEffect(() => {
    if (setTop && lotteryWinnerId.toNumber() > 0) {
      setTop(lotteryWinnerId.toNumber());
    }
  }, [lotteryWinnerId]);

  useEffect(() => {
    if (hasMyParticipation) {
      setLoading(true);
      getLotteryIdByAddress(address || "0")
        .then((res) => {
          const arr = res.map((res) => res.lotteryId);
          const set = new Set(arr);
          setParticipations([...set].sort().reverse());
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [hasMyParticipation]);

  return (
    <Box p="5" display="flex" flexDirection="column" gap={10}>
      <Box>
        <Flex
          justifyContent={"space-between"}
          bg="linear-gradient(329deg, #9900d1 12%, #af117d 100%)"
          p={4}
          rounded={"xl"}
        >
          <Flex justifyContent={"space-between"} gap={4}>
            <Text minW={"50px"}>Top ID</Text>
            <Input
              type="number"
              min={1}
              max={lotteryWinnerId.toNumber()}
              maxW={"100px"}
              value={topId}
              onChange={(ev) => setTop(parseInt(ev.target.value))}
            />
          </Flex>
          <Box>
            <Checkbox isChecked={hasMyParticipation} onChange={(e) => setHasMyParticipation(e.target.checked)}>
              My participations
            </Checkbox>
          </Box>
        </Flex>
        <br />
        <Hide below="sm">
          <Box mb={3}>
            <TableHeader
              templateColumns={{
                base: `repeat(2, minmax(0,1fr))`,
                sm: `repeat(${headers.length - 2}, minmax(0,1fr))`,
                lg: `repeat(${headers.length}, minmax(0,1fr))`
              }}
              headers={headers}
              final
            />
          </Box>
        </Hide>
        <Box>
          {loading && (
            <Stack>
              <Skeleton height="100px" rounded={"2xl"} my={6} />
            </Stack>
          )}
        </Box>
        {idLotteries.map((idLottery) => (
          <LotteryCard key={`idLottery-${idLottery}`} lotteryWinnerId={idLottery} />
        ))}
      </Box>
    </Box>
  );
};

export default TableHistoryLottery;
