import { ChangeEvent, FormEvent, useMemo, useRef } from "react";
import { useBuyTicket } from "./useBuyTicket";
import AbiLottery from "../../../abi/Lottery.abi.json";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Text, Input, Select, Box, Grid, Button, Flex } from "@chakra-ui/react";
import { formatUnits } from "ethers/lib/utils";
import { Web3Button } from "@thirdweb-dev/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { numberFinalToArr, WinnerNumbersToNumberFinal } from "../../../utils/lottery";

interface TPropsBallInput {
  onSelectNumberTicket: (numberTicket: string) => void
  ticketDefault: string
  onActionButton: () => void
}

const BallInput = (props: TPropsBallInput) => {

  const { onSelectNumberTicket, onActionButton, ticketDefault } = props
  const refForm = useRef<HTMLFormElement>(null)

  const ticketArrDefault = useMemo(() => ticketDefault
    ? numberFinalToArr(ticketDefault)
    : ['', '', '', '', '', ''],
    [ticketDefault])

  const handleKeyUp = (currentTabIndex: number, ev: ChangeEvent<HTMLInputElement>) => {
    if (refForm?.current) {
      const valueInput = ev.target?.value
      const value = valueInput.match(/[0-9]/g)

      if (value && value.length > 0) {
        ev.target?.value && (ev.target.value = value[0])

        const elementToFocus: HTMLInputElement | null = refForm
          .current.querySelector(`[tabIndex="${currentTabIndex + 1}"]`)
        if (elementToFocus) {
          elementToFocus.focus();
        }
        handlerButtonSubmit()
      }
    }
  }

  const handlerButtonSubmit = () => {
    if (refForm?.current) {
      refForm.current.requestSubmit()
    }
  }

  const handlerSubmitForm = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const formData = new FormData(ev.target as HTMLFormElement)

    const values = ticketArrDefault.map((_, idx) => formData.get(`numberBall-${idx}`) as string)
    if (values && !values.some(value => value == "")) {
      const numberTicket = WinnerNumbersToNumberFinal(values) || '000000'
      console.log({ numberTicket })
      onSelectNumberTicket(numberTicket)
    }
  }
  return (<>
    <form ref={refForm} onSubmit={handlerSubmitForm}>
      <Grid templateColumns={{ base: 'repeat(4, 1fr)', sm: 'repeat(7, 1fr)', }}>
        {ticketArrDefault.map((el, idx) => (
          <Input
            defaultValue={el}
            rounded={'full'} border={'2px solid #000'} w='3rem' h='3rem'
            tabIndex={idx}
            name={`numberBall-${idx}`}
            max={9}
            min={0}
            onChange={(ev) => handleKeyUp(idx, ev)}
            onFocus={(ev) => ev.target.setSelectionRange(0, 0)}
          />
        ))}
        <Button w='3rem' h='3rem' onClick={onActionButton}><DeleteIcon /></Button>
      </Grid>
    </form>
  </>)
}

export const BuyTicket = () => {
  const {
    setAmountTickets,
    setIdToken,
    idToken,
    handleClickBuyTicketsRandom,
    handleClickBuyTicketsSelected,
    tokens,
    lotteryAddress,
    tokenStorageVSN,
    tickets,
    handleClickAddTicket,
    handlerSelectTicketByIdx,
    handleRemoveInput,
    payedTokenRandom,
    payedTokenSelect
  } = useBuyTicket();
  return (
    <div>
      <Text fontWeight={"semibold"}>Select Token </Text>
      <Select placeholder="Select option" value={idToken} onChange={(ev) => setIdToken(parseInt(ev.target.value))}>
        {tokens &&
          tokens.map(({ symbol }, idx) => (
            <option key={idx} value={idx}>
              {symbol}
            </option>
          ))}
      </Select>
      <Text fontWeight={"medium"}>
        {`Price: ${tokenStorageVSN && formatUnits(tokens[idToken].ticketPrice.toString(), tokens[idToken].decimal)} ${tokens[idToken].symbol}`}
      </Text>
      <Tabs>
        <TabList>
          <Tab>Random Tickets</Tab>
          <Tab>Custom Tickets</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Text>Pay: {`${formatUnits(payedTokenRandom.toString(), tokens[idToken].decimal)} ${tokens[idToken].symbol}`}</Text>
            <Text fontWeight={"semibold"}>Amount </Text>
            <Input
              type="number"
              max="100"
              onChange={(ev) => {
                setAmountTickets(parseInt(ev.target.value));
              }}
            />
            <Box display={'grid'} justifyContent={'center'} alignItems={'center'} mt='8'>
              <Web3Button
                contractAddress={lotteryAddress}
                contractAbi={AbiLottery}
                action={() => handleClickBuyTicketsRandom()}
              >
                Buy Tickets Random
              </Web3Button>
            </Box>

          </TabPanel>
          <TabPanel>

            <Text>Pay: {`${formatUnits(payedTokenSelect.toString(), tokens[idToken].decimal)} ${tokens[idToken].symbol}`}</Text>
            <Text> TICKETS: {tickets.length}</Text>
            <Button onClick={handleClickAddTicket}> +</Button>
            <Flex direction={'column'} gap={4}>
              {tickets.map((ticket, idx) => (
                <Box key={idx} rounded={'full'} border={'2px solid #000'} p={2} >
                  <BallInput
                    ticketDefault={ticket}
                    onSelectNumberTicket={(selectedTicket) => handlerSelectTicketByIdx(selectedTicket, idx)}
                    onActionButton={() => handleRemoveInput(idx)}
                  />
                </Box >)
              )}
            </Flex>
            <Box display={'grid'} justifyContent={'center'} alignItems={'center'} mt='8'>
              <Web3Button
                contractAddress={lotteryAddress}
                contractAbi={AbiLottery}
                action={() => handleClickBuyTicketsSelected()}
              >
                Buy Tickets
              </Web3Button>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};
