import React from "react";

export const IframeComponent = (src) => {
  return (
    <div style={{ width: "85vw", height: "94vh", position: "fixed", overflow: "hidden" }}>
      <iframe
        title="iframe-content"
        className="game-frame"
        src="https://game-free.vercel.app"
        style={{ width: "100%", height: "100%" }}
        frameBorder="0"
      />
    </div>
  );
};
