import React from "react";
import useNFT from "../../hooks/useNFT";
import { Button, Card, CardFooter, Image, Link, SimpleGrid, Box } from "@chakra-ui/react";
import { BiChat, BiLike, BiShare } from "react-icons/bi";

function NFTComponent(props) {
  const { nftId } = props; // Obtén el valor de nftId de las props
  const { nftData, isLoading } = useNFT(); // Pasa nftId al hook
  console.log("nftData", nftData);
  return (
    <>
      {!nftData && (
        <>
          {" "}
          <h3>My NFT DAP</h3>
          <hr />
        </>
      )}

      <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} mt={4} gap={5}>
        {isLoading ? (
          <p>Cargando...</p>
        ) : (
          <>
            {nftData?.map((item) => (
              <Box mx="auto" spacing="10px" w="120px" align="stretch">
                <Card maxW="md" key={item.identifier}>
                  {" "}
                  <Link color="teal.500" href={"https://dap.vsion.io/nft-sad/" + item.identifier}>
                    <Image w={120} objectFit="cover" src={item.image_url} alt={item.name} />{" "}
                  </Link>
                </Card>{" "}
              </Box>
            ))}
          </>
        )}
      </SimpleGrid>
    </>
  );
}

export default NFTComponent;
