import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Grid,
  GridItem,
  Text,
  Flex,
  Spacer,
  ButtonGroup,
  useDisclosure,
  Image
} from "@chakra-ui/react";
import { useContext, useMemo, useRef } from "react";

import { BuyTicket } from "../../components/Lotery/User/BuyTicket";
import { LotteryContext, LotteryProvider } from "../../components/Lotery/contextLottery";
import BallWinner from "../../components/Lotery/BallWinner";
import { numberFinalToArr } from "../../utils/lottery";
import i18next from "i18next";
import { SmartContract, ValidContractInstance, useContractRead } from "@thirdweb-dev/react";
import { BaseContract, BigNumber } from "ethers";
import { TTokenStorage } from "../../components/Lotery/type";
import { formatUnits } from "ethers/lib/utils";
import { tokenValueTxt } from "../../utils/formatters";
import { motion } from "framer-motion";
import { ClaimPrizes } from "../../components/Lotery/User/ClaimPrize";
import bgLotto from "../../assets/images/bg/bglotto.png";
import logoLotto from "../../assets/images/logolotto.png";
import ticketLotto from "../../assets/images/ticket.png";
import TableHistoryLottery from "../../components/Lotery/User/TableHistoryLottery";
import TicketsLotteryId from "../../components/Lotery/User/TicketsLotteryId";
const formatTime = (date: Date) =>
  i18next.t(" {{val, datetime}}", {
    val: date,
    lng: "en",
    formatParams: {
      val: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        date: "",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      }
    }
  });

const ballsColor: string[] = ["blue.500", "orange.500", "green.500", "pink.500", "red.500", "teal.500"];

interface TTokensAcc extends TTokenStorage {
  amount: BigNumber;
}
/*
const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); }
  25% { transform: scale(1.1) rotate(0); }
  50% { transform: scale(1.1) rotate(-3deg); }
  75% { transform: scale(1) rotate(3deg); }
  100% { transform: scale(1) rotate(0); }
`;
 */
//const animation = `${animationKeyframes} 4s ease-in-out infinite`;

export default function Vlottery() {
  return (
    <LotteryProvider>
      <LotteryChildren />
    </LotteryProvider>
  );
}

export function LotteryChildren() {
  // const {  viewLottery } = useContext(LotteryContext)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const {
    currentLotteryId,
    viewLottery,
    viewLotteryWinner,
    tokenStorageVSN,
    tokenStorageUSDT,
    tokenStorageWBNB,
    lotteryContract,
    lotteryWinnerId
  } = useContext(LotteryContext);


  const { data: amountAccVsn } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "amountCollectedPerToken",
    any[],
    BigNumber
  >(lotteryContract, "amountCollectedPerToken", [currentLotteryId, 0]);
  const { data: amountAccUsdt } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "amountCollectedPerToken",
    any[],
    BigNumber
  >(lotteryContract, "amountCollectedPerToken", [currentLotteryId, 1]);
  const { data: amountAccBNB } = useContractRead<
    string,
    ValidContractInstance,
    SmartContract<BaseContract>,
    "amountCollectedPerToken",
    any[],
    BigNumber
  >(lotteryContract, "amountCollectedPerToken", [currentLotteryId, 2]);

  const tokens: TTokensAcc[] = useMemo(() => {
    return [
      { ...tokenStorageVSN, amount: amountAccVsn || BigNumber.from(0) },
      { ...tokenStorageUSDT, amount: amountAccUsdt || BigNumber.from(0) },
      { ...tokenStorageWBNB, amount: amountAccBNB || BigNumber.from(0) }
    ];
  }, [tokenStorageVSN, tokenStorageWBNB, amountAccVsn, amountAccUsdt]);

  const endTime = useMemo(() => {
    return formatTime(new Date(viewLottery.endTime.mul(1000).toNumber()));
  }, [viewLottery?.endTime]);

  const endTimeWinner = useMemo(() => {
    return formatTime(new Date(viewLotteryWinner.endTime.mul(1000).toNumber()));
  }, [viewLotteryWinner?.endTime]);

  const idWinner: string = useMemo(
    () => (viewLottery.finalNumber == 0 ? currentLotteryId.sub(1) : currentLotteryId).toString(),
    [viewLotteryWinner?.finalNumber, currentLotteryId]
  );

  const arrWinner: string[] = useMemo(
    () => numberFinalToArr(viewLotteryWinner.finalNumber),
    [viewLotteryWinner?.finalNumber]
  );

  function getPrizeAcc(amount: BigNumber, feePercent: BigNumber) {
    const percentPrize = BigNumber.from(10000).sub(feePercent);
    return amount.mul(percentPrize).div(10000);
  }

  return (
    <>
      <Box p="5" m="0" className="text-center no-repeat" bgImg={bgLotto}>
        <Grid templateColumns="repeat(1, 1fr)" gap={8}>
          <GridItem justifySelf="center" alignSelf="center">
            <Box maxW="lg" w="100%" p={4} color="white" textAlign="center">
              <Image src={logoLotto} alt="Logo Vsion Lotto" />
            </Box>
            <Box maxW="lg" w="100%" p={4} color="white" textAlign="center">
              <Text textColor="white" fontSize={30} fontWeight="bold" className="bg-ball">
                Prize pool
              </Text>
              {tokens.map((token, idx) => {
                return (
                  <Text key={idx} textColor="#fc9700" fontSize={36} fontWeight="bold">
                    {tokenValueTxt(
                      parseFloat(formatUnits(getPrizeAcc(token.amount, BigNumber.from(0)), token.decimal)),
                      0,
                      token.symbol
                    )}
                  </Text>
                );
              })}
            </Box>{" "}
          </GridItem>
          <GridItem justifySelf="center" alignSelf="center">
            <Box maxW="2-xl" w="100%" p={4} color="white" textAlign="center">
              <Box
                display="flex"
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                className="box-lotto"
                p="4"
                as={motion.div}
              >
                <Flex minWidth="max-content" alignItems="center" gap="2">
                  <Box p="2">
                    <Box className="title-lotto">
                      {" "}
                      <Text fontSize={20}>Lottery ID: {`${currentLotteryId}`}</Text>
                    </Box>

                    <Box className="bg-ball">
                      <Grid
                        templateColumns={{ base: "repeat(3, 1fr)", sm: "repeat(6, 1fr)" }}
                        gap="4"
                        position={"relative"}
                        zIndex={20}
                      >
                        {["?", "?", "?", "?", "?"].map((value, idx) => (
                          <Box
                            key={idx}
                            textColor={"teal.100"}
                            h="100%"
                            display={"grid"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <BallWinner value={value} color={ballsColor[idx]} />
                          </Box>
                        ))}
                      </Grid>
                    </Box>
                    <Spacer />
                    <Box className="title-lotto">
                      <Text textColor="white" fontSize={20} fontWeight="medium">
                        {`Finish at: ${endTime}`}
                      </Text>
                    </Box>
                  </Box>
                  <Spacer />
                  <Box className="relative">
                    <ButtonGroup gap="2" className="buyticket">
                      <Box bgImg={ticketLotto} className="ticketLotto">
                        <Button
                          position="relative"
                          zIndex={20}
                          className="btn-buy-lotto"
                          my={4}
                          onClick={onOpen}
                          bgColor={"#A539FB"}
                          isDisabled={currentLotteryId.eq(idWinner)}
                        >
                          Buy Tickets
                        </Button>
                      </Box>
                    </ButtonGroup>
                  </Box>
                </Flex>
              </Box>
            </Box>
          </GridItem>
          <GridItem justifySelf="center" alignSelf="center">
            <Box
              maxW="3xl"
              mt={5}
              p={10}
              className="last-lotto"
              rounded={12}
              bgColor={"#ffffff1a"}
              border={`3px solid #F7BAFF`}
            >
              <TicketsLotteryId lotteryId={currentLotteryId.toNumber()} />

            </Box>
          </GridItem>
          <GridItem justifySelf="center" alignSelf="center">
            <Box
              maxW="3xl"
              mt={5}
              p={10}
              className="last-lotto"
              rounded={12}
              bgColor={"#ffffff1a"}
              border={`3px solid #F7BAFF`}
            >
              <Text textColor="white" textAlign="center" fontSize={20} fontWeight="semibold" className="bg-ball">
                Last Lottery Id: {idWinner}
              </Text>
              <Box>
                <Grid
                  templateColumns={{ base: "repeat(4, 1fr)", lg: "repeat(6, 1fr)" }}
                  gap={{ base: "2", sm: "6", lg: "8" }}
                  justifyContent="center"
                  p={8}
                >
                  {arrWinner &&
                    arrWinner.map((value, idx) => <BallWinner key={idx} value={value} color={ballsColor[idx]} />)}
                  <GridItem gridArea={{ base: "1 / 4 " }} display={{ base: "block", lg: "none" }}></GridItem>
                  <GridItem gridArea={{ base: "2 / 1 " }} display={{ base: "block", lg: "none" }}></GridItem>
                </Grid>
              </Box>
              <Text textColor="white" textAlign="center" className="bg-ball" fontSize={20} fontWeight="medium">
                {`Finished: ${endTimeWinner}`}
              </Text>
              <Box mt="8">
                <ClaimPrizes lotteryWinnerId={lotteryWinnerId.toNumber()} winnerNumber={viewLotteryWinner.finalNumber} />
              </Box>
            </Box>

          </GridItem>
          <GridItem justifySelf="center" alignSelf="center">
            <Box
              mt={5}
              p={10}
              className="last-lotto"
              rounded={12}
              bgColor={"#ffffff1a"}
              border={`3px solid #F7BAFF`}
            >
              <TableHistoryLottery />
            </Box>
          </GridItem>
        </Grid>
      </Box >
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={"bold"}>Buy Tickets</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BuyTicket />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
