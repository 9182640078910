import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAddress } from '@thirdweb-dev/react';

function useNFT() {
  const address = useAddress();
  const [nftData, setNftData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  console.log('address', address)

  useEffect(() => {
    const options = {
      headers: {
        'accept': 'application/json',
        'x-api-key': '50c53b4a2b404fb8b81fbe37b9f6a433',
      },
    };

    setIsLoading(true); // Indica que la carga está en curso
    if (address) {
      axios.get(`https://api.opensea.io/api/v2/chain/bsc/account/${address}/nfts?collection=vsion-dap-protocol`, options)
        .then((response) => {
          console.log({ response })
          setNftData(response.data.nfts);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error al obtener datos de NFTs:', error);
          setIsLoading(false);
        });
    }
  }, [address]);

  return { nftData, isLoading };
}

export default useNFT;
