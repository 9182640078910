import React, { PropsWithoutRef, useCallback, useEffect, useRef, useState } from "react";

import { Table, Thead, Tbody, Tooltip, Button, Tr, Th, Td, Modal, DarkMode, ModalOverlay, ModalContent, ModalHeader, ModalBody, InputRightAddon, InputGroup, Input, useDisclosure, Text, ModalFooter, Grid } from "@chakra-ui/react";

import AbiERC20 from "../../../../abi/GenericERC20.json";
import AbiDapMarket from '../../../../abi/DapMarket.abi.json';

import { getAllOnSale, addOnSale } from "../../../../firebase/services/onSaleNFT";
import { SmartContract, ValidContractInstance, useAddress, useContract, useContractRead, useContractWrite, useSDK } from "@thirdweb-dev/react";
import { parseUnits } from "ethers/lib/utils";
import LinkBinance from "../../../../components/nft/link-binance";
import { useToast } from "@chakra-ui/react";
import { BaseContract, BigNumber } from "ethers";
import useTokenAux from "./useTokenAux";

import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BiLogoTelegram } from "react-icons/bi";

const schema = yup.object().shape({
  amount: yup.number().integer().min(1).required(),
});

interface TUsers {
  [clave: string]: string | number;
  address: string,
  tokenId: string,
  amount: number,
  unitPrice: string,
  tokenAuxId: string
}

interface TTableRequest {
  id: string,
  onUpdate: () => void
}

const columns = [
  { name: "ADDRESS", uid: "address" },
  { name: "AMOUNT", uid: "amount" },
  { name: "UNIT PRICE", uid: "unitPrice" },
  { name: "CURRENCY", uid: "currency" },
  { name: "ACTIONS", uid: "actions" }
];
const dapMarketAddress = '0x63490Dd8c0e69811D2914B5914e4E6fA17e4F792';

export default function TableOffer(props: PropsWithoutRef<TTableRequest>) {
  const { auxContracts } = useTokenAux()

  const { id, onUpdate } = props

  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { onOpen, isOpen, onClose } = useDisclosure();

  const [items, setOfferRequest] = useState<any[]>([]);
  const [itemsSelected, setOfferRequestSelected] = useState<TUsers>({
    address: '',
    amount: 0,
    tokenAuxId: '',
    tokenId: '',
    unitPrice: "0"
  });

  const address = useAddress()

  const toast = useToast()
  const sdk = useSDK()

  const { contract: contractDapMarket } = useContract(dapMarketAddress, AbiDapMarket)

  const { mutateAsync: buyNFTOnOffer, } = useContractWrite(contractDapMarket, 'buyNFTOnOffer')
  const { mutateAsync: buyNFTOnOfferBNB, } = useContractWrite(contractDapMarket, 'buyNFTOnOfferBNB')

  const { data: Fee = BigNumber.from(0) } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "feePriceOfOffer", unknown[], BigNumber>
    (contractDapMarket, 'feePriceOfOffer')

  const getOfferItems = useCallback(() => {
    getAllOnSale(id).then((data: any[]) => {
      setOfferRequest(data);
    });
  }, [id]);

  const handleClickApprove = useCallback(
    (item: TUsers) => {
      onOpen()
      setOfferRequestSelected(item)

    }, [buyNFTOnOffer, Fee, onUpdate])


  const renderCell = React.useCallback((item: TUsers, columnKey: keyof TUsers | React.Key) => {
    let cellValue: any = '';
    if (columnKey in item) {
      cellValue = item[columnKey];
    }

    switch (columnKey) {
      case "address":
        return (
          <LinkBinance option="address" transaction={cellValue} />
        );
      case "currency":
        const itemToken = auxContracts.find((aux) => aux.idAux == item.tokenAuxId)
        return itemToken
          ? (itemToken.symbol)
          : ('---')

      case "actions":
        return (
          <div className="relative flex items-center gap-2">
            <Tooltip color="primary" label="Buy">
              <Button
                onClick={() => handleClickApprove(item)}
                className="text-2xl cursor-pointer active:opacity-50">
                Buy
              </Button>
            </Tooltip>
          </div>
        );
      default:
        return (<>
          {cellValue}
        </>)
    }
  }, [handleClickApprove, auxContracts]);

  const onSubmitHandler = async (data: any) => {
    try {
      // Realizar acciones de envío del formulario aquí

      try {
        setIsLoading(true);
        const amountCurrency = data.amount;
        if (itemsSelected.tokenAuxId == "0") {
          const totalPrice = parseUnits(itemsSelected.unitPrice.toString(), 18).mul(amountCurrency)

          await buyNFTOnOfferBNB({
            args: [itemsSelected.address, itemsSelected.tokenId, amountCurrency],
            overrides: {
              value: Fee.mul(amountCurrency).add(totalPrice)
            }
          })
        } else if (sdk && itemsSelected) {

          const addrAux = await contractDapMarket?.call('auxTokens', [itemsSelected.tokenAuxId])
          const contractCurrency = await sdk.getContract(addrAux, AbiERC20);
          const decimals = await contractCurrency.call(
            "decimals"
          );

          const allowance = await contractCurrency.call(
            "allowance",
            [address, dapMarketAddress]
          );
          const totalPrice = parseUnits(itemsSelected.unitPrice.toString(), decimals).mul(amountCurrency)

          if (totalPrice.gt(allowance)) {

            const res = await contractCurrency
              .call(
                'approve',
                [
                  dapMarketAddress,
                  totalPrice
                ]
              )

            toast({
              title: 'Success tx.',
              description: (<LinkBinance
                className='text-blue-900'
                textPrev='Transaction complete'
                transaction={res.receipt.transactionHash}
              />),
              status: 'success',
              duration: 9000,
              isClosable: true,
            })
          }
          console.log('Fee', Fee.toString())

          if (Fee.gt(0)) {
            const res = await buyNFTOnOffer({
              args: [itemsSelected.address, itemsSelected.tokenId, amountCurrency],
              overrides: {
                value: Fee.mul(amountCurrency)
              }
            })
            toast({
              title: 'Success tx.',
              description: (<LinkBinance
                className='text-blue-900'
                textPrev='Transaction complete'
                transaction={res.receipt.transactionHash}
              />),
              status: 'success',
              duration: 9000,
              isClosable: true,
            })

          }
        }
        const reqBuy = {
          address: itemsSelected.address,
          tokenId: itemsSelected.tokenId,
          amount: itemsSelected.amount - amountCurrency,
          unitPrice: itemsSelected.unitPrice,
          tokenAuxId: itemsSelected.tokenAuxId
        }
        await addOnSale(reqBuy)
        onUpdate()
        onClose()

      } catch (error: any) {
        toast({
          title: 'Error tx.',
          description: error?.shortMessage ? error.shortMessage : error?.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      } finally {
        setIsLoading(false);
        onClose();
      }

    } catch (error: any) {
      toast({
        title: 'Success tx.',
        description: error?.shortMessage ? error.shortMessage : error?.message,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    }
    reset();
  };

  const formRef = useRef<HTMLFormElement>(null);

  const enviarFormularioDesdeFuera = () => {
    if (formRef?.current) {
      formRef.current.requestSubmit();
    }
  };

  useEffect(() => {
    getOfferItems();
  }, [getOfferItems]);

  return (
    <>
      <Table colorScheme='purple' variant='striped' aria-label="Example table with custom cells">
        <Thead bgColor='purple.900'>
          <Tr>

            {columns.map((column: {
              name: string;
              uid: string;
            }) => (
              <Th key={column.uid} textAlign={column.uid === "actions" ? "center" : "start"}>
                {column.name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody >
          {items.length > 0 && items.map((item: TUsers) => (
            <Tr key={item.id}>
              {columns.map((columnKey: {
                name: string;
                uid: string;
              }) => <Td>{renderCell(item, columnKey.uid)}</Td>)}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Modal isOpen={isOpen} onClose={onClose} colorScheme="gray">
        <DarkMode>
          <ModalOverlay />
          <ModalContent bgColor={'gray.850'}>
            <ModalHeader></ModalHeader>
            <ModalBody>
              <form
                ref={formRef}
                className="flex flex-col gap-4"
                onSubmit={handleSubmit(onSubmitHandler)}
              >
                <Grid>
                  <Text textColor={'white'} ><b>Max DAP on sale:</b> {itemsSelected.amount}</Text>
                  <Text textColor={'white'} ><b>Unit price:</b> {itemsSelected.unitPrice}</Text>
                  <Text textColor={'white'} ><b>Fee:</b> {itemsSelected.unitPrice}</Text>
                </Grid>
                <br />
                <Text textColor={'white'} fontWeight={'semibold'} >Amount</Text>
                <InputGroup size='sm'>


                  <Input
                    {...register("amount")}
                    placeContent="outside"
                    placeholder="Enter amount than buy"
                    type="phone"
                    variant="bordered"
                  />
                  <InputRightAddon>
                    <BiLogoTelegram className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />

                  </InputRightAddon>
                </InputGroup>
                {typeof errors.amount?.message == 'string' && (
                  < Text color={'red.400'}>{errors.amount.message} </Text>)
                }
              </form>
            </ModalBody>
            <ModalFooter>
              <Button isLoading={isLoading} colorScheme="blue" onClick={enviarFormularioDesdeFuera}>
                Buy
              </Button>
            </ModalFooter>
          </ModalContent>
        </DarkMode>
      </Modal >
    </>
  );
}