import {
  Button,
  Flex,
  Grid,
  Input,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, {
  PropsWithoutRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useAddress,
} from "@thirdweb-dev/react";

import Question from "../Question";
import ModalRequest from "./ModalRequest";
import useStateFormRequest from "./useStateFormRequest";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { TOnSaleNft, addOnSale } from "../../../../../firebase/services/onSaleNFT";
import { formatUnits, getAddress, parseUnits } from "ethers/lib/utils";
import FormTransfer from "./FormTransfer";

interface TFormRequestNFt {
  id: string;
  nftInfo: TNFT;
  onUpdate?: () => void;
  onRequest?: () => void;
}

interface TNFT {
  id: string;
  description: string;
  title: string;
  image: string;
  price: number;
}

const schema = yup.object().shape({
  amount: yup.number().required(),
  unitPrice: yup.string().required(),
  tokenAuxId: yup.string().required(),
});

const dapMarketAddress = getAddress('0x63490Dd8c0e69811D2914B5914e4E6fA17e4F792');

const FormRequestNFT = (props: PropsWithoutRef<TFormRequestNFt>) => {
  const { id, nftInfo, onUpdate } = props;
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false)
  const address = useAddress();
  const toast = useToast()

  const {
    supplyNFT,
    maxRequest,
    isApprovedForAll,
    thisBalanceNFT,
    yourBalanceNFT,
    yourRequest,
    balanceVSION,
    handleInputRequest,
    handleRequestNFTClick,
    infoRequest,
    auxContracts,
    FeeOffer,
    FeePriceRequest,
    setApprovalForAll,
    putOnOffer
  } = useStateFormRequest(id, onOpen, onUpdate);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const maxOnSale = useMemo(
    () => (yourBalanceNFT),
    [yourBalanceNFT]
  );

  async function handleInputOnSale(ev: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(ev.target.value || '1').toFixed(0);
    const newValue =
      maxOnSale.lt(value)
        ? maxOnSale.toString()
        : Number(value) > 0
          ? value
          : "0";
    ev.target.value = newValue;
    setValue("amount", Number(newValue));
  }

  const onSubmitHandler = async (data: any) => {
    try {
      setIsLoading(true)
      const amount = data.amount;
      const unitPrice = data.unitPrice;
      const tokenAuxId = data.tokenAuxId;
      const auxToken = auxContracts.find(({ idAux }) => idAux === tokenAuxId);

      if (!isApprovedForAll) {
        await setApprovalForAll({ args: [dapMarketAddress, true] })
      }

      if (auxToken) {
        const res = await putOnOffer({
          args: [
            id,
            parseUnits(unitPrice, auxToken?.decimal),
            amount,
            tokenAuxId,
          ],
          overrides: {
            value: FeeOffer.mul(amount),
            gasLimit: 200000
          }
        });

        console.log(res)

        const formInfoRequest: TOnSaleNft = {
          tokenId: id,
          address: address || getAddress("0"),
          amount,
          unitPrice,
          tokenAuxId,
        };

        await addOnSale(formInfoRequest);
        onUpdate && onUpdate()
        onClose();
      }
    } catch (error: any) {
      toast({
        title: 'error tx.',
        description: error?.shortMessage ? error.shortMessage : error?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setIsLoading(false)
      reset();
    }
  };
  const isAvailable = useMemo(() => {
    return thisBalanceNFT.gt(0)
  }, [thisBalanceNFT])

  useEffect(() => {
    setValue("tokenAuxId", "0");
  }, [setValue]);

  return (
    <>
      <Flex
        flexDirection={'column'}
        gap={8}
      >
        <Grid
          gap={4}
          gridTemplateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", }}
        >
          <span>
            <b>Total supply : </b> {`${supplyNFT || 0} NFT`}
          </span>
          <span>
            <b>Available : </b>{" "}
            {isAvailable
              ? `${maxRequest || 0} of ${thisBalanceNFT || 0} NFT`
              : `sold out`
            }
            <Question body="se toma en cuanta la dispibilidad de los NFT que no se vendieron" />
          </span>
          <span>
            <b> Your Requests : </b> {`${yourRequest || 0} NFT`}
          </span>

          <span>
            <b>Price : </b> {nftInfo.price} VSION
          </span>
          <span>
            <b>Balance : </b>{" "}
            {`${formatUnits(balanceVSION,
              8
            )} VSION`}
          </span>

          <span>
            <b>Fee   X NFT : </b>{" "}
            {`${formatUnits(
              FeePriceRequest,
              18
            )} BNB`}
          </span>
        </Grid>
        <Tabs
          w={'full'}
          size="md"
          aria-label="Tabs form"
        // classNames={{ tab: `${maxOnSale == 0 ? "hidden" : "block"}` }}
        >
          <TabList>
            {maxOnSale.gt(0) && (<Tab>Put on sale NFT</Tab>)}
            {maxOnSale.gt(0) && (<Tab>Transfer my NFT</Tab>)}
            {isAvailable && maxRequest > 0 && (<Tab>Request NFT</Tab>)}
          </TabList>
          <TabPanels>
            {maxOnSale.gt(0) && (<TabPanel key="Buy" title="">
              <form
                className="flex flex-col gap-4"
                onSubmit={handleSubmit(onSubmitHandler)}
              >
                <Text> Amount NFT</Text>
                <Input
                  {...register("amount")}
                  onChange={handleInputOnSale}
                  type="number"
                  placeContent="outside"
                  placeholder="Enter amount NFT buy"
                />
                {typeof errors.amount?.message == 'string' && (
                  < Text color={'red.400'}>{errors.amount.message} </Text>)
                }

                <Text mt={4}>Unit price</Text>
                <Input
                  placeContent="outside"
                  placeholder="Enter amount NFT buy"
                  {...register("unitPrice")}
                />
                {typeof errors.unitPrice?.message == 'string' && (
                  < Text color={'red.400'}>{errors.unitPrice.message} </Text>)
                }
                <Text mt={4}> Currency Allow</Text>
                <Select
                  bgColor={'purple.900'}
                  className="w-[10rem]"
                  variant="underlined"
                  placeContent="outside"
                  aria-label="code country"
                  defaultValue={"0"}
                  {...register("tokenAuxId")}
                >
                  {auxContracts.map((data) => (
                    <option
                      aria-label={`${data.symbol} ${data.idAux}`}
                      key={data.idAux}
                      value={data.idAux}
                    >
                      {data.symbol}
                    </option>
                  ))}
                </Select>
                <br />

                <Button isLoading={isLoading} colorScheme='blue' type="submit">
                  Put on sale
                </Button>
              </form>
            </TabPanel>)}
            {maxOnSale.gt(0) && (
              <TabPanel>
                <FormTransfer id={id} onUpdate={onUpdate} />
              </TabPanel>
            )}

            {maxRequest > 0 && (<TabPanel key="Request" >
              <div className="flex flex-col gap-4">
                <Text> Amount NFT</Text>
                <Input
                  type="number"
                  placeContent="outside"
                  placeholder="Enter amount NFT buy"
                  defaultValue={"1"}
                  onChange={handleInputRequest}
                />
                <br />
                <Button colorScheme='blue' mt={4} onClick={() => handleRequestNFTClick()}>
                  Request NFT
                </Button>
              </div>
            </TabPanel>)}
          </TabPanels>
        </Tabs>
      </Flex>

      <ModalRequest
        isOpen={isOpen}
        onRequest={onUpdate}
        onClose={onClose}
        infoTx={infoRequest}
      />
    </>
  );
};

export default FormRequestNFT;
