import {
  ThirdwebProvider,
  ConnectWallet,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  safeWallet,
  trustWallet,
  rainbowWallet
} from "@thirdweb-dev/react";
import { Binance, BinanceTestnet } from "@thirdweb-dev/chains";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Xacademy from "./pages/xacademy";
import ClassRoom from "./pages/xacademy/ClassRoom";
import Xadmin from "./pages/xadmin";
import Xassets from "./pages/xassets";
import Burn from "./pages/burn";
import XInfo from "./pages/xinfo";
import Xlaunchpad from "./pages/xlaunchpad";
import Vlottery from "./pages/vlottery";
import VlotteryAdmin from "./pages/vlottery/admin";
import Xp2p from "./pages/xp2p";
import Xpredictions from "./pages/xpredictions";
import Xstake from "./pages/xstake";
import Xswap from "./pages/xswap";
import Xwallet from "./pages/xwallet";
import PageNotFound from "./pages/pagenotfound";
import Dap from "./pages/dap";
import App from "./App";
import Farm from "./pages/farm";
import CoursesPage from "./pages/xacademy/Courses";
import P2e from "./pages/p2e";
import CourseDetailPage from "./pages/xacademy/CourseDetail";
import BuyNFT from "./pages/dap/dap-id";

export default function ProviderWeb3() {
  const router = createBrowserRouter([
    {
      path: "/*",
      element: <App />,
      errorElement: <PageNotFound />,

      children: [
        {
          path: "*",
          element: <Xwallet />
        },
        {
          path: "nosotros",
          element: <p>ssl</p>
        }
      ]
    },
    {
      path: "dashboard/*",
      element: <App />,
      errorElement: <PageNotFound />,
      children: [
        {
          path: "academy",
          element: <CoursesPage />
        },
        { path: "academy/detail/:id", element: <CourseDetailPage /> },
        {
          path: "admin",
          element: <Xadmin />
        },
        {
          path: "assets",
          element: <Xassets />
        },
        {
          path: "burn",
          element: <Burn />
        },
        {
          path: "launchpad",
          element: <Xlaunchpad />
        },
        {
          path: "games/lottery/*",
          element: <Vlottery />
        },
        {
          path: "games/lottery/admin/*",
          element: <VlotteryAdmin />
        },
        {
          path: "buy/*",
          element: <Xp2p />
        },
        {
          path: "games/predictions",
          element: <Xpredictions />
        },
        {
          path: "stake",
          element: <Xstake />
        },

        {
          path: "swap/*",
          element: <Xswap />
        },
        {
          path: "academy/:id/:title/*",
          element: <ClassRoom />
        },
        {
          path: "info/*",
          element: <XInfo />
        },
        {
          path: "dap/",
          element: <Dap />
        },
        {
          path: "dap/:id/*",
          element: <BuyNFT />
        },
        { path: "games/p2e/*", element: <P2e /> },

        {
          path: "farm/*",
          element: <Farm />
        },

        {
          path: "*",
          element: <Xwallet />
        }
      ]
    }
  ]);
  // {...Localhost,chainId: 31337,rpc: ["http://localhost:8545"]}
  return (
    <ThirdwebProvider
      activeChain={Binance}
      supportedChains={[Binance, BinanceTestnet]}
      clientId="f13c25d0bd434302c78239df9d12fe4e"
      supportedWallets={[
        metamaskWallet({ recommended: true }),
        trustWallet(),
        walletConnect(),
        coinbaseWallet(),

        safeWallet({
          personalWallets: [trustWallet(), metamaskWallet(), coinbaseWallet(), walletConnect()]
        }),

        rainbowWallet()
      ]}
    >
      <RouterProvider router={router} />
    </ThirdwebProvider>
  );
}
