
import { useMemo, useState } from "react";


import { TRequestNftTransaccion, addRequest } from '../../../../../firebase/services/requestNFT';
import { getUser } from '../../../../../firebase/services/users';

// ABI
import AbiDapNft from '../../../../../abi/DapNft.abi.json';
import AbiVSION from '../../../../../abi/GenericERC20.json';
import AbiDapMarket from '../../../../../abi/DapMarket.abi.json';

import {
  SmartContract,
  ValidContractInstance,
  useAddress,
  useContract,
  useContractRead,
  useContractWrite
} from "@thirdweb-dev/react";
import LinkBinance from "../../../../../components/nft/link-binance";
import { getAddress } from "ethers/lib/utils";
import { useToast } from "@chakra-ui/react";
import { BaseContract, BigNumber } from "ethers";
import useTokenAux from "../useTokenAux";

const dapAddress = getAddress('0xeD39fd97c1dFBa9D1584738A76BBb5aD2Cb3f91b');
const dapMarketAddress = getAddress('0x63490Dd8c0e69811D2914B5914e4E6fA17e4F792');
const vsionAddress = getAddress('0x4BBD4fa12b2B874A13e9555F5C5d0F6aD035ACc3');


const initInfoRequest: TRequestNftTransaccion = {
  address: '',
  amount: 0,
  tokenId: '',
  txHash: ''
}


export default function useStateFormRequest(id: string, onOpen: () => void, onUpdate?: () => void) {

  const { auxContracts } = useTokenAux()

  const address = useAddress()
  const [amountDeposit, setAmountDeposit] = useState("1")
  const [infoRequest, setInfoRequest] = useState<TRequestNftTransaccion>(initInfoRequest)

  const toast = useToast()
  const { contract: contractDap } = useContract(dapAddress, AbiDapNft)
  const { contract: contractDapMarket } = useContract(dapMarketAddress, AbiDapMarket)
  const { contract: contractVSION } = useContract(vsionAddress, AbiVSION)

  const { mutateAsync: RequestNFT } = useContractWrite(contractDap, 'requestNFT')

  const { mutateAsync: approveVSION, } = useContractWrite(contractVSION, 'approve')

  // GETTER
  const { data: Price = BigNumber.from(0) } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "nftPrices", (string)[], BigNumber>
    (contractDap, 'nftPrices', [id])

  const { data: FeePriceRequest = BigNumber.from(0) } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "feePrice", unknown[], BigNumber>
    (contractDap, 'feePrice')

  const { data: FeeOffer = BigNumber.from(0) } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "feePriceOfOffer", unknown[], BigNumber>
    (contractDapMarket, 'feePriceOfOffer')

  const { data: thisBalanceNFT = BigNumber.from(0), refetch: ThisGetBalanceNFT, } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "balanceOf", (string | undefined)[], BigNumber>
    (contractDap, 'balanceOf', [dapAddress, id])

  const { data: yourBalanceNFT = BigNumber.from(0), refetch: GetYourBalanceNFT, } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "balanceOf", (string | undefined)[], BigNumber>
    (contractDap, 'balanceOf', [address, id])

  const { data: balanceVSION = BigNumber.from(0), refetch: GetBalanceVSION } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "balanceOf", (string | undefined)[], BigNumber>
    (contractVSION, 'balanceOf', [address])


  const { data: totalRequest = BigNumber.from(0), refetch: GetTotalRequest } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "totalRequestedNFTsByID", (string)[], BigNumber>
    (contractDap, 'totalRequestedNFTsByID', [id])

  const { data: yourRequest = BigNumber.from(0), refetch: GetYourRequest } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "nftRequestsPerHolderByID", (string | undefined)[], BigNumber>
    (contractDap, 'nftRequestsPerHolderByID', [address, id])

  const { data: supplyNFT = BigNumber.from(0) } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "totalSupply", (string)[], BigNumber>
    (contractDap, 'totalSupply', [id])

  const { data: isApprovedForAll = false } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "isApprovedForAll", (string | undefined)[], boolean>
    (contractDap, 'isApprovedForAll', [address, dapMarketAddress])



  const { mutateAsync: putOnOffer } = useContractWrite(contractDapMarket, "putOnOffer");
  const { mutateAsync: setApprovalForAll } = useContractWrite(contractDap, "setApprovalForAll");



  const maxRequest = useMemo(() => {
    return thisBalanceNFT.sub(totalRequest).toNumber()
  }, [thisBalanceNFT,
    totalRequest])

  async function handleInputRequest(ev: React.ChangeEvent<HTMLInputElement>) {
    const value = ev.target.value;
    const newValue = maxRequest < Number(value)
      ? maxRequest.toString()
      : Number(value) > 0
        ? value
        : '1';
    ev.target.value = newValue
    setAmountDeposit(newValue)
  }

  async function handleRequestNFTClick() {
    try {

      if (!amountDeposit) {
        throw new Error('Indica la cantidad de NFT')
      }
      if (Number(amountDeposit) <= 0) {
        throw new Error('La cantidad de NFT\'s debe ser mayor que 0')
      }

      if (Number(amountDeposit) > maxRequest) {
        throw new Error(`La cantidad de NFT\'s debe ser menor o igual a ${maxRequest}`)
      }
      if (approveVSION && FeePriceRequest.gt(0)) {
        if (Price.mul(amountDeposit).gt(balanceVSION)) {
          throw new Error('No tines suficiente VSION')
        }
        const res = await approveVSION({ args: [dapAddress, Price.mul(amountDeposit)] })

        toast({
          title: 'Success tx.',
          description: (<LinkBinance
            className='text-blue-900'
            textPrev='Transaction complete'
            transaction={res.receipt.transactionHash}
          />),
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        if (RequestNFT) {
          const res = await RequestNFT({
            args: [id, amountDeposit],
            overrides: {
              value: FeePriceRequest.mul(amountDeposit)
            }
          })
          toast({
            title: 'Success tx.',
            description: (<LinkBinance
              className='text-blue-900'
              textPrev='Transaction complete'
              transaction={res.receipt.transactionHash}
            />),
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
          const formRequestTx: TRequestNftTransaccion = {
            address: address || '',
            txHash: res.receipt.transactionHash,
            tokenId: id,
            amount: Number(amountDeposit)
          }
          setInfoRequest(formRequestTx)
          const user = await getUser(address || '')
          if (user) {
            await addRequest(formRequestTx)
            onUpdate && onUpdate()
          } else {
            onOpen()
          }
        }

      }
    } catch (error: any) {
      toast({
        title: 'error tx.',
        description: error?.shortMessage ? error.shortMessage : error?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    } finally {
      GetBalanceVSION()
      GetYourBalanceNFT()
      ThisGetBalanceNFT()
      GetTotalRequest()
      GetYourRequest()
    }
  }

  return {
    supplyNFT,
    maxRequest,
    isApprovedForAll,
    thisBalanceNFT,
    yourBalanceNFT,
    yourRequest,
    balanceVSION,
    handleInputRequest,
    handleRequestNFTClick,
    onOpen,
    putOnOffer,
    infoRequest,
    auxContracts,
    FeeOffer,
    FeePriceRequest,
    setApprovalForAll
  }
}