import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  DarkMode,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";

import { BiLogoTelegram, BiMailSend, BiPhone } from "react-icons/bi";

import {
  addRequest,
  TRequestNft,
  TRequestNftTransaccion,
} from "../../../../../firebase/services/requestNFT";
import { addUser } from "../../../../../firebase/services/users";

import { CountryCodesArr, CountryCodes } from "./flags";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  country: yup.string().required(),
  phone: yup.string().required(),
  telegram: yup.string(),
});

interface TPropsModalRequest {
  infoTx: TRequestNftTransaccion;
  isOpen: boolean;
  onClose: () => void;
  onRequest?: () => any;
}

const ModalRequest = (props: TPropsModalRequest) => {
  const { isOpen, onClose, infoTx, onRequest } = props;
  const toast = useToast()


  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = async (data: any) => {
    try {
      // Realizar acciones de envío del formulario aquí
      if (CountryCodes[data.country]) {
        const email = data.email;
        const phone = `${CountryCodes[data.country].dial_code} ${data.phone}`;
        const telegram = data.telegram;
        const { address, amount, tokenId, txHash } = infoTx;
        const formInfoRequest: TRequestNft = {
          address,
          amount,
          email,
          phone,
          telegram,
          tokenId,
          txHash,
        };
        await addUser({ address, email, phone, telegram });

        await addRequest(formInfoRequest);
        onRequest && onRequest();
        onClose();
      }
    } catch (error: any) {
      toast({
        title: 'Success tx.',
        description: error?.shortMessage ? error.shortMessage : error?.message,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    }
    reset();
  };

  const formRef = useRef<HTMLFormElement>(null);

  const enviarFormularioDesdeFuera = () => {
    if (formRef?.current) {
      formRef.current.requestSubmit();
    }
  };
  useEffect(() => {
    setValue("country", "PE");
  }, [setValue]);

  return (

    <Modal isOpen={isOpen} onClose={onClose} colorScheme="gray">
      <DarkMode>
        <ModalOverlay />
        <ModalContent bgColor={'gray.850'}>
          <ModalHeader></ModalHeader>
          <ModalBody>
            <form
              ref={formRef}
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <Text>Email</Text>
              <InputGroup size='sm'>
                <Input
                  {...register("email")}
                  autoFocus
                  placeContent="outside"
                  placeholder="Enter your email"
                  variant="bordered"
                  isRequired
                />

                <InputRightAddon>
                  <BiMailSend className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                </InputRightAddon>
              </InputGroup>
              {typeof errors.email?.message == 'string' && (
                < Text color={'red.400'}>{errors.email.message} </Text>)
              }
              <br />
              <Text >Phones</Text>
              <InputGroup size='sm'>
                <InputLeftAddon>
                  <Select
                    w={'7rem'}
                    h={'2rem'}
                    bgColor={'gray.900'}
                    variant="underlined"
                    placeContent="outside"
                    aria-label="code country"
                    defaultValue={["PE"]}
                    {...register("country")}
                  >
                    {CountryCodesArr.map((country) => (
                      <option
                        aria-label={`${country.name} ${country.dial_code}`}
                        key={country.code}
                      >
                        {country.name} {country.dial_code}
                      </option>
                    ))}
                  </Select>
                </InputLeftAddon>
                <Input
                  {...register("phone")}
                  placeContent="outside"
                  placeholder="Enter your phone number"
                  type="phone"
                  variant="bordered"
                  isRequired
                />
                <InputRightAddon>
                  <BiPhone className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />

                </InputRightAddon>
              </InputGroup>
              <br />
              <Text >Telegram</Text>
              <InputGroup size='sm'>


                <Input
                  {...register("telegram")}
                  placeContent="outside"
                  placeholder="Enter your telegram user"
                  type="phone"
                  variant="bordered"
                />
                <InputRightAddon>
                  <BiLogoTelegram className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />

                </InputRightAddon>
              </InputGroup>
              {typeof errors.telegram?.message == 'string' && (
                < Text color={'red.400'}>{errors.telegram.message} </Text>)
              }
            </form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={enviarFormularioDesdeFuera}>
              Send Request
            </Button>
          </ModalFooter>
        </ModalContent>
      </DarkMode>
    </Modal >
  );
};

export default ModalRequest;
