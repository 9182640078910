import {
  Button,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, {
  PropsWithoutRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  SmartContract,
  ValidContractInstance,
  useAddress,
  useContract,
  useContractRead,
  useContractWrite,
} from "@thirdweb-dev/react";


import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { getAddress } from "ethers/lib/utils";
import { BaseContract, BigNumber } from "ethers";

import AbiDapNft from '../../../../../abi/DapNft.abi.json';
import AbiDapMarket from '../../../../../abi/DapMarket.abi.json';


interface TFormTransfer {
  id: string;
  onUpdate?: () => void;
  onRequest?: () => void;
}

const schema = yup.object().shape({
  amount: yup.number().required(),
  address: yup.string().matches(/^0x[a-fA-F0-9]{40}$/, 'Address is wrong').required(),
});

const dapAddress = getAddress('0xeD39fd97c1dFBa9D1584738A76BBb5aD2Cb3f91b');
const dapMarketAddress = getAddress('0x63490Dd8c0e69811D2914B5914e4E6fA17e4F792');

const FormTransfer = (props: PropsWithoutRef<TFormTransfer>) => {
  const { id, onUpdate } = props;
  const [isLoading, setIsLoading] = useState(false)
  const address = useAddress();
  const toast = useToast()

  const { contract: contractDap } = useContract(dapAddress, AbiDapNft)
  const { contract: contractDapMarket } = useContract(dapMarketAddress, AbiDapMarket)


  const { data: isApprovedForAll = false } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "isApprovedForAll", (string | undefined)[], boolean>
    (contractDap, 'isApprovedForAll', [address, dapMarketAddress])

  const { data: yourBalanceNFT = BigNumber.from(0), refetch: GetYourBalanceNFT, } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "balanceOf", (string | undefined)[], BigNumber>
    (contractDap, 'balanceOf', [address, id])

  const { data: FeeTransfer = BigNumber.from(0) } = useContractRead
    <string, ValidContractInstance, SmartContract<BaseContract>, "feePriceOfTransfer", unknown[], BigNumber>
    (contractDapMarket, 'feePriceOfTransfer')


  const { mutateAsync: setApprovalForAll } = useContractWrite(contractDap, "setApprovalForAll");
  const { mutateAsync: transferDap } = useContractWrite(contractDapMarket, "transfer");


  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const maxOnSale = useMemo(
    () => (yourBalanceNFT),
    [yourBalanceNFT]
  );

  async function handleInputOnSale(ev: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(ev.target.value || '1').toFixed(0);
    const newValue =
      maxOnSale.lt(value)
        ? maxOnSale.toString()
        : Number(value) > 0
          ? value
          : "0";
    ev.target.value = newValue;
    setValue("amount", Number(newValue));
  }

  const onSubmitHandler = async (data: any) => {
    try {
      setIsLoading(true)
      const amount = data.amount;
      const addressTo = data.address;

      if (!isApprovedForAll) {
        await setApprovalForAll({ args: [dapMarketAddress, true] })
      }

      const res = await transferDap({
        args: [
          addressTo,
          id,
          amount,
        ],
        overrides: {
          value: FeeTransfer.mul(amount),
          gasLimit: 200000
        }
      });
      toast({
        title: 'Success tx.',
        description: res.receipt.transactionHash,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })

      onUpdate && onUpdate()
    } catch (error: any) {
      toast({
        title: 'error tx.',
        description: error?.shortMessage ? error.shortMessage : error?.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      console.error(error)
    } finally {
      setIsLoading(false)
      reset();
      GetYourBalanceNFT
    }
  };

  useEffect(() => {
    setValue("amount", 1);
  }, [setValue]);

  return (
    <>

      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Text> Amount NFT</Text>
        <Input
          {...register("amount")}
          onChange={handleInputOnSale}
          type="number"
          placeContent="outside"
          placeholder="Enter amount NFT buy"
        />
        {typeof errors.amount?.message == 'string' && (
          < Text color={'red.400'}>{errors.amount.message} </Text>)
        }
        <br />
        <Text mt={4}> To (address)</Text>
        <Input
          {...register("address")}
          onChange={handleInputOnSale}
          placeContent="outside"
          placeholder="Enter address"
        />
        {typeof errors.address?.message == 'string' && (
          < Text color={'red.400'}>{errors.address.message} </Text>)
        }

        <br />
        <Button mt={4} isLoading={isLoading} colorScheme='blue' type="submit">
          Transfer
        </Button>
      </form>

    </>
  );
};

export default FormTransfer;
