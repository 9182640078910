import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Grid,
  GridItem,
  Heading,
  Text,
  Image,
  Stat,
  StatNumber,
  StatHelpText
} from "@chakra-ui/react";
import MarketPlaceDap from "./marketPlace/MarketPlaceDap";
//import Getstarted from "./marketPlace/Getstarted";
import himg from "../../assets/images/wpaper.jpg";
import imgHeader from "../../assets/images/icons-headers/08.png";
import HeaderPage from "../../components/HeaderPage";
import ButtonReward from "./ButtonReward";
import CardBalanceToken from "./CardBalanceToken";
import ButtonRewardDapAll from "./ButtonRewardDapAll";
import ButtonRewardHolder from "./ButtonRewardHolder";

const Header = () =>
  HeaderPage(
    "MY NFT DAP",
    " VSION",

    "",
    `Your NFT represents % participation of Vsion Hub Ecosystem and Criptovision Labs equity.
`,
    "View Open sea",
    imgHeader,
    "https://opensea.io/collection/vsion-dap-protocol"
  );
const Dap = () => {
  return (
    <>
      {" "}
      <Box
        p="5"
        h="100vh"
        className="text-center"
        background="linear-gradient(97.53deg, #0f071d 5.6%, #0f071d 59.16%, #af117d 200.34%)"
      >
        {" "}
        <Header />

        <Grid gap={0} pl={{ base: 0, md: 10 }}>
          {" "}
          <GridItem>
            {" "}
            <h3 className="titl">Pool Rewards DAP</h3>
          </GridItem>
        </Grid>
        <Grid
          templateColumns={{
            base: "repeat(1,minmax(0,1fr))",
            sm: "repeat(2,minmax(0,1fr))",
            md: "repeat(3,minmax(0,1fr))"
          }}
          gap={{ base: 3, md: 6 }}
          p={{ base: 2, md: 10 }}
        >
          <GridItem>
            <CardBalanceToken idToken="0" />
          </GridItem>
          <GridItem>
            <CardBalanceToken idToken="1" />
          </GridItem>
          <GridItem>
            <CardBalanceToken idToken="2" />
          </GridItem>
        </Grid>
        <Grid gap={0} pl={{ base: 0, md: 10 }} mt={5}>
          {" "}
          <GridItem>
            {" "}
            <h3 className="titl">Holders</h3>
          </GridItem>
          <ButtonRewardHolder />
        </Grid>
        <Grid gap={0} pl={{ base: 0, md: 10 }}>
          {" "}
          <GridItem>
            {" "}
            <h3 className="titl">My NFT's</h3>
          </GridItem>
          <ButtonRewardDapAll />
        </Grid>
        <MarketPlaceDap />
      </Box>
    </>
  );
};

export default Dap;
