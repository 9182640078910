import { Flex, Link, Tooltip, Text, Button } from "@chakra-ui/react";

export const Audited = ({ ...rest }) => {
  // we don't have UI/UX for switching accounts when clicking the yat banner
  // account 0 is gonna have to cut it

  return (
    <Tooltip label="Info report" placement="right">
      <Link
        href="#"
        isExternal
        display="block"
        aria-label="Info report"
        _hover={{ boxShadow: "0 0 0 2px var(--chakra-colors-chakra-body-text) inset" }}
        borderRadius="xl"
        {...rest}
      >
        <Flex
          borderRadius="15px"
          border="1px solid #302939"
          flexDirection="column"
          color="#fff"
          bgSize="cover"
          bgPosition="center"
          justifyContent="flex-start"
          alignItems="start"
          p="16px"
          minH="80px"
          minW="218px"
          background="linear-gradient(97.53deg, #0f071d 5.6%, #0f071d 59.16%, #af117d 200.34%)"
        >
          <Text fontSize="sm" color="white" fontWeight="bold">
            Audits
          </Text>
          <Text fontSize="xs" className="center" color="#00bcd4" mb="10px">
            Please check our Report
          </Text>
          <Link w="100%" href="https://coinsult.net/projects/vsion/" isExternal>
            <Button
              fontSize="10px"
              fontWeight="bold"
              w="100%"
              mb={1}
              bg={"#00bcd4"}
              _active={{
                bg: "#9900d1",
                transform: "none",
                borderColor: "transparent"
              }}
              _focus={{
                boxShadow: "none"
              }}
              _hover={{
                bg: "#9900d1",
                transform: "none",
                borderColor: "transparent"
              }}
              color="white"
            >
              View Audited Coinsult
            </Button>
          </Link>

          <Link
            isExternal
            w="100%"
            href="https://app.solidproof.io/projects/vsion
"
          >
            <Button
              fontSize="10px"
              fontWeight="bold"
              w="100%"
              bg={"#00bcd4"}
              _hover={{
                bg: "#9900d1",
                transform: "none",
                borderColor: "transparent"
              }}
              _active={{
                bg: "#9900d1",
                transform: "none",
                borderColor: "transparent"
              }}
              _focus={{
                boxShadow: "none"
              }}
              color="white"
            >
              View Audited Solid Prooof
            </Button>
          </Link>
        </Flex>
      </Link>
    </Tooltip>
  );
};
