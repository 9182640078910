import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { SmartContract, ValidContractInstance, useAddress, useContract, useContractRead, useContractWrite } from '@thirdweb-dev/react';
import DapRewardAbi from '../../../abi/DapReward.abi.json'
import utilStyles from "../marketPlace/utils.module.css";

import GenericERC20Abi from '../../../abi/GenericERC20.json'

import { formatUnits, getAddress, parseEther } from 'ethers/lib/utils';
import { BaseContract, BigNumber } from 'ethers';
import { ChangeEvent, FormEvent, useMemo, useRef, useState } from 'react';
import { tokenValueTxt } from '../../../utils/formatters';
import CountDown from '../../../components/CountDown';
interface TPropsButtonRewardHolder {
}

const tokens = [
  { id: "0", symbol: "USDT" },
  { id: "1", symbol: "VSN" },
  { id: "2", symbol: "VSION" },
]

// const unitFeeDapAmount = parseEther("0.006") //1.5
// const maxFeeDapAmount = parseEther("0.02") //5
// const feeHolderAmount = parseEther("0.028")//7
const feeWithdraw = parseEther("0.028")//7
const factorCalculate = "100000000"
const dapRewardAddr = "0xc8364B02bBE37D4C2E856B14599F4E5eF9c188CC"
const vsionAddr = "0x4BBD4fa12b2B874A13e9555F5C5d0F6aD035ACc3"
const vsnRewardAddr = "0x1Aaaef9426db36ebE4D5Ef23DA81f141dbC30725"
const ButtonRewardHolder: React.FC<TPropsButtonRewardHolder> = () => {

  const address = useAddress
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();
  const toast = useToast()
  const cancelRef = useRef<any>();
  const finalRef = useRef(null);
  const [selectedTokenId, setSelectedTokenId] = useState('')

  const { contract: DapRewardContract } = useContract(dapRewardAddr, DapRewardAbi)
  const { data: userInfo = { lastRewardTimeDAP: BigNumber.from(0) } } =
    useContractRead(DapRewardContract, "userInfo", [address])

  const { data: rewardToken = { token: getAddress("0xc8364B02bBE37D4C2E856B14599F4E5eF9c188CC"), } } = useContractRead(DapRewardContract, "rewardTokens", [selectedTokenId])
  const { contract: TokenRewardContract } = useContract(rewardToken.token, GenericERC20Abi)
  const { data: decimalRewardToken = 7 } = useContractRead(TokenRewardContract, "decimals")
  const { data: symbolRewardToken = '' } = useContractRead(TokenRewardContract, "symbol")

  const { contract: VsionContract } = useContract(vsionAddr, GenericERC20Abi)
  const { contract: VsnContract } = useContract(vsnRewardAddr, GenericERC20Abi)
  const { data: vsionBalance = BigNumber.from(0) } =
    useContractRead(VsionContract, "balanceOf", [address])
  const { data: vsionBurned = BigNumber.from(0) } =
    useContractRead(VsionContract, "balanceOf", ['0x000000000000000000000000000000000000dEaD'])
  const { data: vsionTotalSupplay = BigNumber.from(0) } =
    useContractRead(VsionContract, "totalSupply", [])
  const percentVsion = useMemo(() => {
    if (vsionTotalSupplay.gt(0) || vsionBurned.gt(0)) {
      return vsionBalance
        .mul(factorCalculate)
        .div(vsionTotalSupplay.add(vsionBurned))
    }
    return BigNumber.from(0)
  }
    , [
      vsionBalance,
      vsionBurned,
      vsionTotalSupplay
    ]);

  const { data: vsnBalance = BigNumber.from(0) } =
    useContractRead(VsnContract, "balanceOf", [address])
  const { data: vsnBurned = BigNumber.from(0) } =
    useContractRead(VsnContract, "balanceOf", ['0x000000000000000000000000000000000000dEaD'])
  const { data: vsnTotalSupplay = BigNumber.from(0) } =
    useContractRead(VsnContract, "totalSupply", [])

  const percentVsn = useMemo(() => {
    if (vsnTotalSupplay.gt(0) || vsnBurned.gt(0)) {
      return vsnBalance
        .mul(factorCalculate)
        .div(vsnTotalSupplay.add(vsnBurned))
    }
    return BigNumber.from(0)
  }, [
    vsnBalance,
    vsnBurned,
    vsnTotalSupplay
  ]);

  const { data: balanceRewardToken = BigNumber.from(0) } =
    useContractRead<
      string,
      ValidContractInstance,
      SmartContract<BaseContract>,
      "balanceOf",
      string[],
      BigNumber>
      (TokenRewardContract, "balanceOf", [dapRewardAddr])

  const { data: pool = {
    lastUpdatePool: BigNumber.from(0),
    initialAmount: BigNumber.from(0),
    remainingAmount: BigNumber.from(0)
  } } =
    useContractRead<
      string,
      ValidContractInstance,
      SmartContract<BaseContract>,
      "poolDAPByRewardToken",
      string[], {
        lastUpdatePool: BigNumber
        initialAmount: BigNumber
        remainingAmount: BigNumber
      }>
      (DapRewardContract, "poolDAPByRewardToken", [selectedTokenId])

  const { mutateAsync: withdrawHolder } = useContractWrite(DapRewardContract, "withdrawHolder")

  const handlerSubmitWithdrawDap = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    const formData = new FormData(ev.target as HTMLFormElement)

    const tokenID = formData.get(`currency`) as string
    if (tokenID === "") {
      toast({
        title: 'Error tx.',
        description: "Select currency reward ",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      return
    }
    onOpenAlert()
  }

  const poolCurrent = useMemo(() => {
    if (pool.lastUpdatePool.toNumber() + 60 * 60 * 24 * 60 < Date.now() / 1000) {
      return balanceRewardToken.mul(10).div(100)
    }
    return pool.initialAmount
  }, [pool])

  const reward = useMemo(() => {
    if (percentVsion.gt(0) && percentVsn.gt(0)) {
      const promPercent = percentVsion.add(percentVsn).div(2)
      // formula: promedio precent VSION-VSN(balanceHolder) * ( pool )
      return poolCurrent.mul(promPercent).div(factorCalculate);
    }
    return BigNumber.from(0)
  }, [percentVsion, percentVsn, poolCurrent])

  const hasReward = useMemo(() => {
    return userInfo.lastRewardTimeDAP.toNumber() + 60 * 60 * 24 * 60 < Date.now() / 1000
  }, [userInfo?.lastRewardTimeDAP])

  const handlerCurency = (ev: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTokenId(ev.target.value)
  }

  return (
    <div>
      <Button
        borderRadius="12px"
        textColor="white"
        w="275px"
        h="40px"
        mb={2}
        className={utilStyles.btn}
        background="linear-gradient(25deg, #af0f7d, #58086c)"
        _hover={{
          background: "linear-gradient(329deg, #9900d1 12%, #af117d 100%)",
          textColor: "white",
          border: "none",
          fontSize: "20px"
        }}
        onClick={onOpen}
        isDisabled={vsionBalance.eq(0) && vsnBalance.eq(0)}
      >
        Holder Earn
      </Button>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={"bold"}>Withdraw Holder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            {!hasReward
              ? (
                <Box fontSize={'2xl'} textAlign={'center'} >
                  <Text fontWeight={'semibold'}>
                    Next Withdraw
                  </Text>
                  <Text>
                    <CountDown time={userInfo.lastRewardTimeDAP.toNumber() + 60 * 60 * 24 * 60} />
                  </Text>
                </Box>
              )
              : (<form onSubmit={(ev) => handlerSubmitWithdrawDap(ev)}>
                <Text fontWeight={'semibold'} mb={4}>Select the currency with which you will receive the reward.</Text>
                <Select
                  name='currency'
                  placeholder="Select option"
                  defaultValue={""}
                  onChange={handlerCurency}
                >
                  {tokens &&
                    tokens.map(({ symbol, id }, idx) => (
                      <option key={idx} value={id}>
                        {symbol}
                      </option>
                    ))}
                </Select>
                <br />
                <b>Fee: </b>{tokenValueTxt(parseInt(formatUnits(feeWithdraw.toString() || 0, 15)), 3, "BNB")}
                <br />

                <b>Reward: ≈{tokenValueTxt(
                  parseInt(formatUnits(reward.toString(), decimalRewardToken - 4)),
                  4,
                  symbolRewardToken
                )}</b>
                <br />
                <Grid templateColumns="repeat(1, 1fr)" gap={6}>
                  <Button isDisabled={!hasReward} type='submit'>WithdrawDap</Button>
                </Grid>
              </form>)
            }
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        isOpen={isOpenAlert}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>You reward</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            If you withdraw, you will not be able to withdraw the reward with other currency and the next your reward is in 60 days.
            <br />
            <b>Fee: </b>{tokenValueTxt(parseInt(formatUnits(feeWithdraw.toString() || 0, 18 - 3)),
              3,
              "BNB")}
            <br />
            <b>Reward: ≈{tokenValueTxt(
              parseInt(formatUnits(reward.toString(), decimalRewardToken - 4)),
              4,
              symbolRewardToken
            )}</b>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme="red" ref={cancelRef} onClick={onCloseAlert}>
              Not Withdraw
            </Button>
            <Button colorScheme="green" ml={3} onClick={() => withdrawHolder({
              args: [
                selectedTokenId
              ],
              overrides: {
                value: feeWithdraw
              }
            })}>
              Withdraw
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div >
  );
};

export default ButtonRewardHolder;